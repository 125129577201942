import { OrderFormat, OutletListItem } from '@ancon/wildcat-types'
import getOutletOrderFormats from '@ancon/wildcat-utils/outlet/getOutletOrderFormats'

/**
 * If delivery integration is enabled, we don't show the delivery fee because
 * it depends on the delivery address and the delivery provider
 */
export default function isOutletDeliveryFeeShown(outlet: OutletListItem) {
  const orderFormats = getOutletOrderFormats(outlet)

  const isDeliverySupported = orderFormats.find(
    orderFormat => orderFormat === OrderFormat.Delivery,
  )

  return isDeliverySupported && !outlet.deliveryIntegrationEnabled
}
