import Skeleton from '@ancon/wildcat-ui/web/orderweb/Skeleton'

import styles from './OutletDetailsModalSkeleton.module.scss'

export default function OutletDetailModalSkeleton() {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.mapView} />

      <div className={styles.metaContainer}>
        <Skeleton className={styles.outletName} />
        <div className={styles.orderFormats}>
          <Skeleton className={styles.orderFormat} />
          <Skeleton className={styles.orderFormat} />
          <Skeleton className={styles.orderFormat} />
        </div>
        <div className={styles.businessInfo}>
          <Skeleton className={styles.businessNumber} />
          <Skeleton className={styles.legalName} />
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.infoItemContainer}>
          <Skeleton className={styles.w30} />
          <Skeleton className={styles.w50} />
        </div>
        <div className={styles.infoItemContainer}>
          <Skeleton className={styles.w50} />
          <Skeleton />
        </div>
        <div className={styles.infoItemContainer}>
          <Skeleton className={styles.w40} />
        </div>
        <div className={styles.infoItemContainer}>
          <Skeleton className={styles.w60} />
        </div>
      </div>
    </div>
  )
}
