import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import moment from 'moment'
import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import { OpeningHoursTable } from '@ancon/wildcat-utils/outlet/getListOutletOpeningHoursTable'

import styles from './OutletOpeningHoursSet.module.scss'

type OutletOpeningHoursSetProps = {
  item: OpeningHoursTable
}

export default function OutletOpeningHoursSet({
  item,
}: OutletOpeningHoursSetProps) {
  const { t } = useTranslation('outlet')
  const { openingHours } = item

  return (
    <div className={styles.container}>
      {openingHours.map(({ isoWeekday, open, adjusted, closed }) => {
        const isClosed = !open.length && !adjusted.length && !closed.length
        const hasExceptions = adjusted.length > 0 || closed.length > 0
        const day = moment().isoWeekday(isoWeekday).format('dddd')
        const isSameDay = moment().isoWeekday() === isoWeekday

        function getExceptions() {
          return (
            <>
              {closed.length < 1 &&
                adjusted.map(({ id, start, end, reason }) => (
                  <div key={id} className={styles.exceptionDay}>
                    <BodyText color="body-1">
                      {moment(start).format('HH.mm')} -{' '}
                      {moment(end).format('HH.mm')}
                    </BodyText>
                    {!!reason && (
                      <BodyText color="body-1">({reason}) </BodyText>
                    )}
                  </div>
                ))}
              {closed.map(({ id, reason }) => (
                <div key={id} className={styles.exceptionDay}>
                  <BodyText color="body-1" className={styles.closedDay}>
                    {t('closed')}
                  </BodyText>
                  {!!reason && <BodyText color="body-1">({reason})</BodyText>}
                </div>
              ))}
            </>
          )
        }

        return (
          <div className={styles.openingHoursRow} key={isoWeekday}>
            <div className={styles.openingDayCell}>
              <BodyText
                color="body-1"
                className={clsx(styles.day, {
                  [styles.isToday]: isSameDay,
                })}
              >
                {day}
                {isSameDay && ` (${t('today')})`}
              </BodyText>
            </div>
            <div className={styles.openingPeriodCell}>
              {isClosed && (
                <BodyText color="body-1" className={styles.closedDay}>
                  {t('closed')}
                </BodyText>
              )}

              {closed.length < 1 &&
                adjusted.length < 1 &&
                open.map(({ id, start, end }) => (
                  <BodyText key={id} color="body-1">
                    {moment(start).format('HH.mm')} -{' '}
                    {moment(end).format('HH.mm')}
                  </BodyText>
                ))}
              {hasExceptions && getExceptions()}
            </div>
          </div>
        )
      })}
    </div>
  )
}
