export default function useIsMobileDevice() {
  const match = window?.matchMedia

  if (match) {
    const mq = match('(pointer:coarse)')
    return mq.matches
  }

  return false
}
