import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import BodyText from '../../../app/components/BodyText'

import styles from './OutletBusinessInformation.module.scss'

type OutletBusinessInformationProps = {
  businessNumber: string
  legalName: string
  className?: string
}

export default function OutletBusinessInformation({
  businessNumber,
  legalName,
  className,
}: OutletBusinessInformationProps) {
  const { t } = useTranslation('common')

  return (
    <div className={clsx(styles.container, className)}>
      <BodyText color="body-1">
        {t('outletBusinessInfo.businessNumber', { businessNumber })}
      </BodyText>
      <BodyText color="body-1">
        {t('outletBusinessInfo.legalName', { legalName })}
      </BodyText>
    </div>
  )
}
