'use client'

import { useEffect } from 'react'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import Button from '@ancon/wildcat-ui/web/orderweb/Button'

import { fetchOutletDetails } from '../../store/outletThunks'
import Modal from '../../../app/components/modal/Modal'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  outletDetailsIdASelector,
  outletDetailsSelector,
  outletIsOutletDetailsPendingSelector,
  outletIsVisibleOutletDetailsModalSelector,
  outletListItemSelector,
} from '../../store/outletSelector'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { outletSetIsVisibleOutletDetailsModal } from '../../store/outletSlice'
import { outletsSelectedOutletListItemSelector } from '../../../outlets/store/outletsSelectors'

import OutletDetailsMetadata from './OutletDetailsMetaData'
import styles from './OutletDetailsModal.module.scss'
import OutletDetailModalSkeleton from './OutletDetailsModalSkeleton'
import OutletDetailsOpeningHours from './OutletDetailsOpeningHours'
import OutletInformation from './OutletInformation'

function OutletDetailModalHeader() {
  const dispatch = useAppDispatch()

  function handleModalClose() {
    dispatch(outletSetIsVisibleOutletDetailsModal(false))
  }

  return (
    <div className={styles.modalHeader}>
      <Button variant="secondary" onClick={handleModalClose}>
        <CloseIcon />
      </Button>
    </div>
  )
}

type OutletDetailsModalProps = {
  isInOutletsPage?: boolean
}

export default function OutletDetailsModal({
  isInOutletsPage,
}: OutletDetailsModalProps) {
  const dispatch = useAppDispatch()
  const isVisible = useAppSelector(outletIsVisibleOutletDetailsModalSelector)
  const outletDetailsId = useAppSelector(outletDetailsIdASelector)
  const isOutletDetailsPending = useAppSelector(
    outletIsOutletDetailsPendingSelector,
  )
  const outletDetails = useAppSelector(outletDetailsSelector)
  // Outlet
  const outletOutletListItem = useAppSelector(outletListItemSelector)
  // Outlets
  const outletsOutletListItem = useAppSelector(
    outletsSelectedOutletListItemSelector,
  )

  // Current outlet
  const outlet = isInOutletsPage ? outletsOutletListItem : outletOutletListItem

  function handleModalClose() {
    dispatch(outletSetIsVisibleOutletDetailsModal(false))
  }

  // Fetch outlet details if not exists
  useEffect(() => {
    if (
      !isInOutletsPage &&
      isVisible &&
      !isOutletDetailsPending &&
      outlet &&
      outlet.id !== outletDetailsId
    ) {
      dispatch(fetchOutletDetails({ outletId: outlet.id }))
    }
  }, [
    isVisible,
    outlet,
    outletDetailsId,
    dispatch,
    isOutletDetailsPending,
    isInOutletsPage,
  ])

  return (
    <Modal
      className={styles.modal}
      isOpen={isVisible}
      onClose={handleModalClose}
      mobileModalMode="bottom-sheet"
      CustomModalHeader={OutletDetailModalHeader}
    >
      <div className={styles.container}>
        {outletDetails && outlet ? (
          <>
            <div className={styles.topContainer}>
              {!isOutletDetailsPending && outletDetails && (
                <OutletDetailsMetadata outletDetails={outletDetails} />
              )}
            </div>
            <div className={styles.bottomContainer}>
              <OutletDetailsOpeningHours outletListItem={outlet} />
              <OutletInformation
                outletDetails={outletDetails}
                outletListItem={outlet}
              />
            </div>
          </>
        ) : (
          <OutletDetailModalSkeleton />
        )}
      </div>
    </Modal>
  )
}
